import React, { useState, useEffect } from 'react';
import Navbar from './Navbar/Navbar';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Logo from '../../assets/images/logo.svg';

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  display: flex;
  transition: 0.4s;
  padding-bottom: 20px;
  padding-top: ${props => (props.sticky ? '20px' : '50px')};
  background: ${props => (props.sticky ? '#3A0D90' : 'transparent')};
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      padding-top: ${props => (props.sticky ? '20px' : '35px')};
      padding-bottom: ${props => (props.sticky ? '20px' : '35px')};
  }
`;

const HeaderContainer = styled.div`
  max-width: 1690px;
  padding: 0 65px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
      padding: 0 30px;
  }
`;

const LogoLink = styled(props => <Link {...props} />)`
  font-size: 0;
  position: relative;
  z-index: 2;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    img {
      width: 130px;
    }
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    img {
      width: 110px;
    }
  }
`;

const MainHeader = () => {
  const [sticky, setSticky] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 100) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };


  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);

    return () => {
      handleScroll();
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Header sticky={sticky}>
      <HeaderContainer>
        <LogoLink to="/">
          <img src={Logo} alt="Mercho"/>
        </LogoLink>
        <Navbar sticky={sticky}/>
      </HeaderContainer>
    </Header>
  );
};

export default MainHeader;
