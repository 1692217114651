/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from "gatsby";

import '../assets/stylesheets/layout.scss';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../assets/stylesheets/theme';
import CookieConsent from 'react-cookie-consent';
import MainHeader from '../components/MainHeader/MainHeader';

import AOS from 'aos';
import 'aos/dist/aos.css';

const MoreButton = styled.button`
  color: #fff;
  font-size: 15px;
  border-radius: 16px;
  padding: 6px 24px;
  border: 2px solid #fff;
  background: transparent;
  margin-left: auto;
  margin-right: -10px;
  
  &:hover {
    background-color: #fff;
    color: #4f289a;
  }
  
  @media screen and (max-width: 600px) { 
    margin-right: 0;
    margin-left: 20px;
  }
`;

const Layout = ({ children }) => {
  useEffect(() => {
    AOS.init({
      disable: function() {
        const maxWidth = 768;
        return window.innerWidth < maxWidth;
      },
      duration: 1000
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MainHeader />
      <main>{children}</main>

      <CookieConsent
        location="bottom"
        buttonText="Akceptuj"
        declineButtonText="Więcej"
        style={{ background: "#3A0D90", textAlign: "center" }}
        buttonStyle={{ color: "#fff", fontSize: "15px", background: "linear-gradient(60deg,#f38726,#efba35)", borderRadius: "16px", padding: "8px 24px" }}
        cookieName="gatsby-gdpr-google-analytics">
        Ta strona wykorzystuje pliki cookies
        <MoreButton onClick={() => { navigate("/polityka-prywatnosci/") }}>Więcej</MoreButton>
      </CookieConsent>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
