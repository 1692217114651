let theme = {
  primary: '#4f289a',
  breakpoints: {
    desktopLrg: '1600px',
    desktopMed: '1200px',
    desktopSm: '1024px',
    tablet: '768px',
    mobile: '480px'
  },
  minBreakpoints: {
    desktopLrg: '1601px',
    desktopMed: '1201px',
    desktopSm: '1025px',
    tablet: '769px',
    mobile: '481px'
  }
};

export default theme
