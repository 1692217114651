import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import navigation from '../../../data/navigation';
import scrollTo from 'gatsby-plugin-smoothscroll';

const Nav = styled.nav`
  margin-left: auto;
  margin-right: -5px;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: inline-block;
    margin: 0;
    pointer-events: none;
    transition: 0.3s;
    width: 26px;
    height: 26px;
    position: absolute;
    right: 25px;
    top: calc(50% - 15px);
  }
`;

const NavLinks = styled.ul`
  display: flex;
  margin: 0 0 0 auto;
  padding-left: 0;
  list-style: none;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      display: block;
  }
`;

const NavLinkItem = styled.li`
  margin: 0 20px;

  @media screen and (max-width: ${props => props.theme.breakpoints.desktopLrg}) {
    margin: 0 16px;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.desktopSm}) {
    margin: 0 6px;
  }
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
    height: 8px;
    line-height: 8px;
    white-space: nowrap;
    transform: scaleY(0.2);
    transition: 0.3s, opacity 1s;
    margin: 0;

    &:nth-child(1) {
      a {
        letter-spacing: -6px;
      }
    }

    &:nth-child(2) {
      a {
        letter-spacing: -5px;
        
        &::before {
          width: 16px;
          left: calc(50% - 8px);
        }
      }
    }

    &:nth-child(n + 4) {
      a {
        letter-spacing: -8px;
        opacity: 0;
        margin-top: -7px;
      }
    }
  }

  &:last-child {
    margin-right: 0;

    //a {
    //  font-weight: 400;
    //}
  }
`;

const StyledLink = styled(props => <Link {...props} />)`
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  font-family: 'Visby CF';
  transition: 0.3s;
  position: relative;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      color: rgba(255, 255, 255, 0);
      font-size: 0;
      display: block;
  }
  
  &:before {
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transform: translateY(-50%) scaleY(5);
      transition: 0.3s;
    }  
  }
`;

const StyledExternalLink = styled.a`
  cursor: pointer;
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 13px;
  font-weight: 700;
  font-family: 'Visby CF';
  transition: 0.3s;
  position: relative;
  
  @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      color: rgba(255, 255, 255, 0);
      font-size: 0;
      display: block;
  }
  
  &:before {
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transform: translateY(-50%) scaleY(5);
      transition: 0.3s;
    }  
  }
`;

const ToggleNavButton = styled.label`
  @media screen and (min-width: ${props => props.theme.minBreakpoints.tablet}) {
      display: none;
  }
  
  span {
    position: absolute;
    display: inline-block;
    width: 26px;
    height: 26px;
    margin: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 100%;
    transition: 0.6s;
    right: 25px;
    top: calc(50% - 15px);
  
    // Shadow on hover
    &:hover {
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
    
    &:before, &:after {
      position: absolute;
      content: '';
      top: 50%;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      border-radius: 5px;
      transition: 0.3s;
    }
  
    &:before {
      transform: translateY(-50%) rotate(45deg) scale(0);
    }
  
    &:after {
      transform: translateY(-50%) rotate(-45deg) scale(0);
    }
  }
`;

const StyledToggleInput = styled.input`
  position: absolute;
  left: -100%;
  top: -100%;
  
  @media screen and (min-width: ${props => props.theme.minBreakpoints.tablet}) {
      display: none;
  }
  
  &:focus {
    & ~ ${ToggleNavButton} span {
      box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }
  }
  
  &:checked {
    @media screen and (max-width: ${props => props.theme.breakpoints.tablet}) {
      & ~ ${ToggleNavButton} span {
        box-shadow: 0 0 0 420px rgba(0, 0, 0, 0.85), inset 0 0 0 20px rgba(0, 0, 0, 0.85);
        
        &::before {
          transform: translateY(-50%) rotate(45deg) scale(1);
        }
    
        &::after {
          transform: translateY(-50%) rotate(-45deg) scale(1);
        }
      }
      
      & ~ ${Nav} {
      margin-bottom: 100px;
      pointer-events: auto;
      transform: translate(-150px, 50px);
  
        ${NavLinkItem} {
          height: 40px;
          margin-top: 0;
          opacity: 1;
          transform: scaleY(1);
          transition: 0.3s, opacity 0.1s;
          
          &:nth-child(n + 4) {
            ${StyledLink} {
              margin-top: 0;
            }
          }
          
          ${StyledLink} {
            color: rgba(255, 255, 255, 1);
            letter-spacing: 0;
            font-size: 13px;
            opacity: 1;
            
            &:before {
              opacity: 0;
            }
          }
          
         ${StyledExternalLink} {
            color: rgba(255, 255, 255, 1);
            letter-spacing: 0;
            font-size: 13px;
            margin-top: 0;
            opacity: 1;
            
            &:before {
              opacity: 0;
            }
         }
        }
      }
    }
  }
`;

const Navbar = ({ sticky }) => {
  return (
    <>
      <StyledToggleInput id="toggle" type="checkbox"/>
      <ToggleNavButton htmlFor="toggle">
        <span></span>
      </ToggleNavButton>
      <Nav>
        <NavLinks>
          {navigation.map(({ url, name, internal }) => {

            const handleClick = () => {
              const navCheckbox = document.querySelector('#toggle');
              navCheckbox.checked = false;
              scrollTo(url);
            };

            return (
              <NavLinkItem key={url}>
                {internal ? <StyledLink to={url} sticky={sticky}>{name}</StyledLink> : <StyledExternalLink className="scroll" sticky={sticky} onClick={() => handleClick()}>{name}</StyledExternalLink>}
              </NavLinkItem>
            );
          })}
        </NavLinks>
      </Nav>
    </>
  );
};

export default Navbar;